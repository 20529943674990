* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header.header.MuiPaper-elevation {
    /* background-color: #0C0833; */
}

.breadcrumb a {
    color: #0C0833;
}

.btn-d button:hover,
.btn-d button:active,
.btn-d button {
    background-color: #0C0833;
}


label {
    color: #0C0833;
}

.table-style {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    border: 1px solid #000;
    font-weight: 400;

}


.login_paper.MuiPaper-elevation {
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin: 20px;
}
